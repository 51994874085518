import React from "react";
import { Container } from "react-bootstrap";
import ScrollToTop from "../../components/ScrollToTop";
import SEO from "../../components/SEO";
import Breadcrumb from "../../container/Breadcrumb/Breadcrumb";
import Footer from "../../container/Footer/Footer";
import Header from "../../partials/header/Header";
import "./termsConditions.css";
const TermsConditions = () => {
  return (
    <React.Fragment>
      <SEO title="OneEventas || About" />
      <Header />

      <Breadcrumb
        image="images/bg/breadcrumb-bg.jpg"
        title="We are an e-commerce solution provider located in Bangalore"
        content="Home"
        contentTwo="Terms Conditions"
      />
      <div className="my-5 float-start w-100 content_page">
        <Container className="py-5">
          <div class="content">
            <h1 className="text-center">Terms and Conditions</h1>
            <h2>
              <b>Terms and Conditions</b>
            </h2>
            <p>
              These Terms and Conditions (the <b>"T&amp;C"</b>) govern your use
              of our application, in relation to the Open Network for Digital
              Commerce, for mobile and handheld devices (<b>"Pincode"</b>) on
              the Pincode Shopping Solutions Private Limited platform (the
              "Platform"). For clarity, the Platform refers to any Platform
              owned/subscribed/used by Pincode Shopping Solutions Private
              Limited, not limited to websites, mobile applications, devices,
              URLs/links, notifications, chatbot, or any other communication
              medium used to provide services to Users.
            </p>
            <p>
              Pincode is owned and operated by Pincode Shopping Solutions
              Private Limited, a private limited company incorporated under the
              Companies Act, 2013 and having its registered office at Office-2,
              Floor 6, Wing B, Block A, Salarpuria Softzone, Bellandur Village,
              Varthur Hobli, Outer Ring Road, Bangalore South, Bangalore,
              Karnataka, India, 560103. For the purpose of these T&amp;C,
              wherever the context so requires,{" "}
              <b>
                "Buyer(s)", "you", "You", "your", "Your", "user", or "User",
                "End User"
              </b>{" "}
              shall mean any natural or legal person who shall transact on
              Pincode by providing registration data while registering on
              Pincode as a registered user using any computer system, phone or
              handheld device. The terms{" "}
              <b>"PSSPL", "We", "we", "Us", "us", "Our" or "our"</b> shall mean
              Pincode Shopping Solutions Private Limited.
            </p>
            <p>
              Users are requested to carefully read the T&amp;C before using
              Pincode or registering on Pincode or accessing any material or
              information through Pincode. By clicking on the <b>"Proceed"</b>{" "}
              button, or through the continued use of Pincode, Users accept
              these T&amp;C of Use and agree to be legally bound by the same. If
              You do not agree to these T&amp;C, You may not use the services on
              Pincode, and We request You to uninstall Pincode. By installing,
              downloading or even merely using Pincode, You shall be contracting
              with PSSPL and You signify Your acceptance to this T&amp;C and
              other PSSPL policies (including but not limited to the
              Cancellation &amp; Refund Policy, Privacy Policy and Take Down
              Policy) as posted on Pincode and amended from time to time, which
              takes effect on the date on which You use Pincode, and thereby
              create a legally binding arrangement to abide by the same.
            </p>
            <p>
              PSSPL enables transactions on its Pincode application between
              Buyers and Sellers dealing in various categories of goods and
              services. (<b>"Pincode Services"</b>). You can choose and place
              orders (<b>"Orders"</b>) on Pincode, from a variety of goods and
              services (<b>"Products"</b>) listed and offered for sale by
              various Sellers including but not limited to the restaurants,
              kiranas, retail stores, electronic stores, supermarkets, grocery
              stores etc ("Seller(s)") through the Seller application (
              <b>"Seller App"</b>), which is a Platform on which the various
              Sellers are available. The goods and services are sold on a
              business to consumer basis only for personal consumption and not
              for resale.
            </p>
            <p>
              PSSPL may facilitate the delivery of such Orders or completion of
              tasks at select localities of serviceable cities across India (
              <b>"Delivery Services"</b>) provided by a Seller or by connecting
              third party service providers i.e. pick-up and delivery partners (
              <b>"Logistic Partner"</b>) who will be responsible for providing
              the pick-up and Delivery Services initiated by the users of
              Pincode (Buyers or Sellers). For both Pincode Services and
              Delivery Services, PSSPL is merely acting as an intermediary
              between the Sellers and Buyers and/or Logistic Partners and
              Buyers/Sellers.
            </p>
            <p>
              For the Delivery Services, PSSPL may charge the Buyer a delivery
              fee (inclusive of applicable taxes whenever not expressly
              mentioned) on behalf of the Logistic Partner/ Seller App/ party
              appointing the Logistic Partner, determined on the basis of
              various factors including but not limited to distance covered,
              time taken, demand for Delivery Services/tasks, real time analysis
              of traffic and weather conditions, seasonal peaks or such other
              parameters as may be determined from time to time.
            </p>
            <p>
              Use of and access to Pincode is offered to Users upon the
              condition of acceptance of all the terms, conditions and notices
              contained in these T&amp;C and Privacy Policy, along with any
              amendments made by PSSPL at its sole discretion and posted on
              Pincode from time to time and subject to the above, PSSPL grants
              Users a personal, non-exclusive, non-transferable, limited
              privilege to enter and use Pincode and the PSSPL Services.
            </p>
            <h2>
              <b>Eligibility</b>
            </h2>
            <p>By accessing Pincode, You represent that:-</p>
            <ul>
              <li>You are 18 years of age or older;</li>
              <li>
                You are capable of entering into a contract /legally binding
                agreement;
              </li>
              <li>
                You have the right, authority and capability to enter into this
                T&amp;C and abide by all the provisions of the "T&amp;C" of
                PSSPL Services.
              </li>
              <li>
                You are not barred or otherwise legally prohibited from
                accessing or using services of PSSPL or its entities under the
                laws of India.
              </li>
              <li>
                You are not impersonating any person or entity, or falsely
                stating Your age or affiliation with any person or entity.
              </li>
            </ul>
            <p>
              Without prejudice to the other legal remedies which PSSPL may
              avail, PSSPL and its entities shall reserve the right to terminate
              Your contract to use Pincode in case of any incorrect
              representation of the above-mentioned conditions.
            </p>
            <h2>
              <b>Amendments</b>
            </h2>
            <p>
              PSSPL retains the right to modify or amend these T&amp;C. You can
              access the latest version of these T&amp;C at any given time on
              Pincode. You should regularly review the T&amp;C on Pincode. Your
              continued use of and access to Pincode shall be Your consent to
              such changes. In the event the modified T&amp;C are not acceptable
              to You, You should discontinue accessing Pincode.
            </p>
            <h2>
              <b>Access to Pincode Services</b>
            </h2>
            <p>
              We will do Our utmost to ensure that availability of Pincode will
              be uninterrupted and that transmissions will be error-free.
              However, due to the nature of the internet and third party
              intervention/services being provided, this cannot be guaranteed.
              Also, Your access to Pincode may also be occasionally suspended or
              restricted for any reason whatsoever, including but not limited to
              repairs, maintenance, or the introduction of new facilities or
              services at any time without prior notice.
            </p>
            <h2>
              <b>Privacy</b>
            </h2>
            <p>
              Please review our{" "}
              <a href="https://www.phonepe.com/privacy-policy/app/">
                Privacy Policy
              </a>{" "}
              (<b>"Privacy Policy"</b>) also available at
              https://www.phonepe.com/privacy-policy/app/, , which governs Your
              visit to Pincode, to understand Our practices. The personal
              information / data provided to Us by You during the course of Your
              usage of Pincode will be treated as in accordance with the Privacy
              Policy and applicable laws and regulations. If You object to Your
              information being transferred or used, please do not use Pincode.
            </p>
            <h2>
              <b>Use of Pincode Services</b>
            </h2>
            <p>
              All commercial/contractual terms are offered by and agreed to
              between Buyers and Sellers or Seller App as the case maybe, alone
              with respect to Products being offered by the Sellers. The
              commercial/contractual terms include without limitation price,
              applicable taxes, shipping costs, payment terms, date, period and
              mode of delivery, warranties related to Product and after sales
              services related to Products. PSSPL does not have any control and
              does not determine or advise or in any way involve itself in the
              offering or acceptance of such commercial/contractual terms
              between the Buyers and Sellers. The price of the product and
              services offered by the Seller are determined by the Seller itself
              and PSSPL has no role to play in such determination of price in
              any way whatsoever.
            </p>
            <p>
              PSSPL neither make any representation or warranty as to specifics
              (such as quality, value, saleability etc.) of the Products
              proposed to be sold or offered to be sold or purchased on Pincode
              nor does implicitly or explicitly support or endorse the sale or
              purchase of any Products on Pincode. PSSPL accepts no liability
              for any errors or omissions, whether on behalf of itself or third
              parties.
            </p>
            <p>
              PSSPL does not make any representation or warranty with respect to
              any aspect of the services being provided by the Logistic Partner
              through Pincode including but not limited to pick up and Delivery
              Services to the Sellers or Buyers as the case may be.
            </p>
            <p>
              PSSPL assumes the role of facilitator and does not at any point of
              time during any transaction between Buyer and Seller and/or Buyer
              and Logistic Partner on Pincode come into or take possession of
              any of the Products offered by Seller or Logistic Partner. At no
              time shall PSSPL hold any right, title or interest over the
              Products nor shall PSSPL have any obligations or liabilities in
              respect of such contract entered into between Buyer and Seller
              and/or Buyer and Logistic Partner.
            </p>
            <p>
              Pincode is only an application for communication, and it is agreed
              that the contract for sale of any of the Products shall be a
              strictly bipartite contract between the Seller and the Buyer. In
              case of complaints from the Buyer pertaining to efficacy, quality,
              or any other such issues, PSSPL shall notify the same to Seller
              App. The Seller App in coordination with the Seller shall be
              liable for redressing Buyer complaints. In the event You raise any
              complaint on any Seller, We shall assist You to the best of Our
              abilities by providing relevant information to You, such as
              details of the Seller and the specific Order to which the
              complaint relates, to enable satisfactory resolution of the
              complaint.
            </p>
            <p>
              Similar to the above, Pincode is only an application for
              communication with the Logistic Partner and does not provide any
              pick-up and Delivery Services with respect to the Orders placed by
              Buyers on Pincode as it is merely facilitating Delivery Services
              by connecting the Buyers with the Logistic Partner through
              Pincode. In case of complaints by the Buyers for deficiency or
              lapse in the Delivery Services provided by Logistic Partner, PSSPL
              shall notify the same to the Logistic Partner and also assist
              Buyers to the best of its abilities to enable satisfactory
              resolution of the complaint.
            </p>
            <h2>
              <b>Your conduct</b>
            </h2>
            <p>
              You agree, undertake and confirm that Your use of Pincode shall be
              strictly governed by the following binding principles:
            </p>
            <p>
              You shall not host, display, upload, modify, publish, transmit,
              update or share any information which:
            </p>
            <ul>
              <li>
                belongs to another person and to which the User does not have
                any right;
              </li>
              <li>
                is defamatory, obscene, pornographic, paedophilic, invasive of
                another's privacy, including bodily privacy, insulting, or
                harassing on the basis of gender, libellous, racially, or
                ethnically objectionable, relating or encouraging money
                laundering or gambling, or otherwise inconsistent with or
                contrary to the laws in force;
              </li>
              <li>is harmful to child;</li>
              <li>
                infringes any patent, trademark, copyright, or other proprietary
                rights;
              </li>
              <li>violates any law for the time being in force;</li>
              <li> impersonates another person;</li>
              <li>
                threatens the unity, integrity, defence, security or sovereignty
                of India, friendly relations with foreign States, or public
                order, or causes incitement to the commission of any cognisable
                offence or prevents investigation of any offence or is insulting
                other nation;
              </li>
              <li>
                contains software virus or any other computer code, file or
                program designed to interrupt, destroy, or limit the
                functionality of any computer resource;
              </li>
              <li>
                is patently false and untrue, and is written or published in any
                form, with the intent to mislead or harass a person, entity, or
                agency for financial gain or to cause any injury to any person;
              </li>
              <li>
                disrupt or interfere with the security of, or otherwise cause
                harm to, Pincode, systems resources, accounts, passwords,
                servers, or networks connected to or accessible through Pincode
                or any affiliated or linked sites;
              </li>
              <li>violate the T&amp;C contained herein or elsewhere; and</li>
              <li>
                reverse engineer, modify, copy, distribute, transmit, display,
                perform, reproduce, publish, license, create derivative works
                from, transfer, or sell any information or software obtained
                from Pincode.
              </li>
            </ul>
            <p>
              You shall not use any "deep-link", "page-scrape", "robot",
              "spider" or other automatic device, program, algorithm or
              methodology, or any similar or equivalent manual process, to
              access, acquire, copy or monitor any portion of Pincode or any
              Content, or in any way reproduce or circumvent the navigational
              structure or presentation of Pincode or any Content, to obtain or
              attempt to obtain any materials, documents or information through
              any means not purposely made available through Pincode. We reserve
              Our right to bar any such activity.
            </p>
            <p>
              You shall not attempt to gain unauthorized access to any portion
              or feature of Pincode, or any other systems or networks connected
              to Pincode or to any server, computer, network, or to any of the
              services offered on or through Pincode, by hacking, password
              "mining" or any other illegitimate means.
            </p>
            <p>
              You shall not probe, scan or test the vulnerability of Pincode or
              any network connected to Pincode nor breach the security or
              authentication measures on Pincode or any network connected to
              Pincode. You shall not reverse look-up/engineer, trace or seek to
              trace any information on any other User of or visitor to Pincode,
              or any other customer, including any Account on Pincode not owned
              by You, to its source, or exploit Pincode or any service or
              information made available or offered by or through Pincode, in
              any way where the purpose is to reveal any information, including
              but not limited to personal identification or information, other
              than Your own information, as provided for by Pincode.
            </p>
            <p>
              You shall not make any disparaging or defamatory statement(s) or
              comment(s) about Us or the brand name or domain name used by Us or
              otherwise engage in any conduct or action that might tarnish the
              image or reputation, of PSSPL or Sellers on Pincode or otherwise
              tarnish or dilute any of PSSPL's trade or service Marks, trade
              name and/or goodwill associated with such trade or service marks,
              trade name as may be owned or used by Us.
            </p>
            <p>
              You agree not to use any device, software or routine to interfere
              or attempt to interfere with the proper working of Pincode or the
              Open Network for Digital Commerce or any transaction being
              conducted on Pincode, or with any other person's use of Pincode.
            </p>
            <p>
              You shall not forge headers or otherwise manipulate identifiers in
              order to disguise the origin of any message or transmittal You
              send to Us on or through Pincode or any service offered on or
              through Pincode.
            </p>
            <p>
              You shall at all times ensure full compliance with the applicable
              provisions of the Information Technology Act, 2000 and rules
              thereunder as applicable and as amended from time to time and also
              all applicable domestic laws, rules and regulations (including the
              provisions of any applicable Exchange Control Laws or regulations
              in force) and International Laws, Foreign Exchange Laws, Statutes,
              Ordinances and Regulations (including, but not limited to GST,
              Income Tax, Custom Duty and Local and Central Levies) regarding
              Your use of Our service and Your listing, purchase, solicitation
              of offers to purchase, and sale of Products. You shall not engage
              in any transaction in an item or service, which is prohibited by
              the provisions of any applicable law including exchange control
              laws or regulations for the time being in force.
            </p>
            <p>
              Solely to enable Us to use the information You supply Us with, so
              that We are not violating any rights You might have in Your
              information, You agree to grant Us a non-exclusive, worldwide,
              perpetual, irrevocable, royalty-free, sub-licensable (through
              multiple tiers) right to exercise the copyright, publicity,
              database rights or any other rights You have in Your information,
              in any media now known or not currently known, with respect to
              Your information, which will include sharing of Your information
              with Our affiliates, subsidiaries and third parties. We will only
              use and/or share Your information in accordance with the T&amp;C
              and Privacy Policy applicable to use of Pincode.
            </p>
            <p>
              PSSPL reserves the right, but has no obligation, to monitor the
              materials posted on Pincode. PSSPL shall have the right to remove
              or edit any content that in its sole discretion violates, or is
              alleged to violate, any applicable law or either the spirit or
              letter of these T&amp;C. Please be advised that such Content
              posted does not necessarily reflect PSSPL's views. In no event
              shall PSSPL assume or have any responsibility or liability for any
              Content posted or for any claims, damages or losses resulting from
              use of Content and/or appearance of Content on Pincode.
            </p>
            <p>
              You understand and acknowledge that by using Pincode or any of the
              Pincode Services, You may encounter Content that may be deemed by
              some Users to be offensive, indecent, or objectionable, which
              Content may or may not be identified as such. You agree to use
              Pincode and any Pincode Services at Your sole risk and that to the
              fullest extent permitted under applicable law. PSSPL shall have no
              liability to You for Content that may be deemed offensive,
              indecent, or objectionable to You.
            </p>
            <h2>
              <b>Account Registration</b>
            </h2>
            <p>
              You may access Pincode by registering to create an account (
              <b>"Account"</b>) and become a member (<b>"Membership"</b>).
            </p>
            <p>
              If You use Pincode, You shall be responsible for maintaining the
              confidentiality of Your display name and password, and You shall
              be responsible for all activities that occur under Your display
              name and password. You agree that if You provide any information
              that is untrue, inaccurate, not current or incomplete, or We have
              reasonable grounds to suspect that such information is untrue,
              inaccurate, not current or incomplete, or not in accordance with
              the this T&amp;C, We shall have the right to indefinitely suspend
              or terminate or block access of Your Membership on Pincode and
              refuse to provide You with access to Pincode. You shall be solely
              liable and responsible for all the activities undertaken under
              Your Account, and any consequences therefrom.
            </p>
            <p>
              Your mobile phone number and/or e-mail address is treated as Your
              primary identifier on Pincode. It is Your responsibility to ensure
              that Your mobile phone number and Your email address is up to date
              on Pincode at all times. You agree to notify Us promptly if Your
              mobile phone number or e-mail address changes by updating the same
              on Pincode through a onetime password verification.
            </p>
            <h2>
              <b>Order flow, booking and payments</b>
            </h2>
            <p>
              An Order placed on Pincode will involve one or more of the
              following steps:
            </p>
            <ol class="upper-roman">
              <li>
                The Buyer will initiate a search for a product or a service
                through Pincode
              </li>
              <li>Pincode will relay this search request to the gateway.</li>
              <li>
                The gateway will check with the Open Network for Digital
                Commerce Registry to identify the relevant Seller Apps that have
                the requested product or service available in the specified
                location. Upon identification, the search request will be
                broadcast to the relevant Seller Apps
              </li>
              <li>
                Seller Apps will relay the information to the Sellers listed on
                their Seller App to confirm the offering of the product or
                service requested, along with the terms and conditions of the
                offering.
              </li>
              <li>
                Upon Seller's confirmation, Seller App will relay the offer to
                Pincode through the gateway.
              </li>
              <li>
                Pincode will display the offer to the Buyer. If the Buyer is
                satisfied with the offering, the Buyer may confirm the purchase
                of the product and Pincode may place a purchase order with the
                Seller through the Seller App. The Seller App will in turn
                forward the purchase order to the Seller for confirmation.
              </li>
              <li>
                Upon Seller's confirmation, the Seller App will confirm the
                Order on behalf of the Seller.
              </li>
            </ol>
            <p>
              Pincode allows the Buyers to place Orders and upon acceptance of
              such Orders by the Sellers, PSSPL or the Seller App will, subject
              to these T&amp;C, facilitate delivery of Products, through the
              Logistic Partner.
            </p>
            <p>
              PSSPL does not own, sell, resell on its own such Products offered
              by the Sellers, and/or does not control the Sellers or the related
              services provided in connection thereof. Buyer understands that
              any Order that he/she places shall be subject to the terms and
              conditions set out in these T&amp;C including, but not limited to,
              product or service availability, delivery location serviceability,
              and acceptance of Orders by Sellers/Logistic Partners.
            </p>
            <p>
              All payments made against the Orders on Pincode by You shall be
              compulsorily in Indian Rupees acceptable in the Republic of India.
              Pincode will not facilitate transactions with respect to any other
              form of currency with respect to the Orders made on Pincode. You
              can pay by (i) credit card or debit card or UPI or net banking or
              wallet etc; (ii) any other RBI approved payment method at the time
              of booking an Order; or (iii) You understand, accept and agree
              that the payment facility provided by Pincode is neither a banking
              nor financial service but is merely a facilitator providing an
              electronic, automated online electronic payment, receiving payment
              on delivery, collection and remittance facility for the
              transactions on Pincode using the existing authorized banking
              infrastructure and credit card payment gateway networks. Further,
              by providing payment facility, Pincode is neither acting as
              trustees nor acting in a fiduciary capacity with respect to the
              transaction or the transaction price.
            </p>
            <p>
              Buyers acknowledge and agree that PSSPL shall accept payments from
              Buyers on behalf of the Seller or Logistic Partner, as the case
              may be. Upon Your payment of amounts to Us, which are due to the
              Seller or Logistic Partner, Your payment obligation to the Seller
              or Logistic Partner for such amounts is completed, and We shall
              settle such amounts to the Seller or Logistic Partner.
            </p>
            <p>
              Buyer agrees to pay for the total amount for the Order placed on
              Pincode. PSSPL will collect the total amount in accordance with
              these T&amp;C and the pricing terms set forth in the applicable
              listing of product or service for the particular Seller, apart
              from the delivery fees for Delivery Services. Please note that We
              cannot control any amount (e.g. convenience fee) that may be
              charged to Buyer by his/her bank related to Our collection of the
              total amount, and We disclaim all liability in this regard.
            </p>
            <p>
              In connection with Buyer's Order, he/she will be asked to provide
              customary billing information such as name, billing address and
              card information either to Us or Our third party payment
              processor. Buyer agrees to pay Us for the Order placed by him on
              Pincode, in accordance with these T&amp;C, using the methods
              described above. Buyer hereby authorizes the collection of such
              amounts by charging the card provided as part of requesting the
              booking, either directly by Us or indirectly, via a third party
              online payment processor or by one of the payment methods
              described on Pincode. If Buyer is directed to Our third-party
              payment processor, he/she may be subject to terms and conditions
              governing use of that third party's service and that third party's
              personal information collection practices. Please review such
              terms and conditions, Privacy Policy and Take Down Policy before
              using Pincode services. Once the Order is confirmed You will
              receive a confirmation email summarizing the confirmed booking.
            </p>
            <p>
              The Buyer may be required to furnish certain details for the
              purpose of making payment, which may be saved for future
              convenience. The information provided by the Buyer pertaining to
              his payment instrument details will not be utilized or shared with
              any third party unless required in relation to fraud verifications
              or by law, regulation or court order. Buyer shall be solely
              responsible for the security and confidentiality of his/her
              credit/ debit card details. We expressly disclaim all liabilities
              that may arise as a consequence of any unauthorized use of Your
              credit/ debit card.
            </p>
            <p>
              The prices reflected on Pincode, including packaging or handling
              charges, are determined by the Seller and are listed based on
              Seller's information.
            </p>
            <p>
              The Seller shall be solely responsible for any warranty/guarantee
              of the Products sold to the Buyers and in no event shall be the
              responsibility of PSSPL.
            </p>
            <p>
              The transactions are bilateral between the Seller and Buyer, and
              between Seller/Buyer and Logistic Partner, therefore, PSSPL is not
              liable to charge or deposit any taxes applicable on such
              transactions.
            </p>
            <h2>
              <b>Return, cancellations, exchange and refund</b>
            </h2>
            <p>
              Pincode will endeavour to assist Buyers who have bonafide disputes
              on the Products provided to them by the Sellers. The return policy
              and return policy period depends on the product category, the
              Seller's refund policy and the Seller.
            </p>
            <p>
              Replacement: If at the time of delivery and/or within the
              applicable return policy period, if any defect is found, then the
              buyer of the product/s or service/s can ask for replacement of the
              product/s or service/s from the Seller subject to the following
              terms and conditions;
            </p>
            <ol>
              <li>
                Notify Seller of any defects in the product/s or service/s at
                the time of delivery of the product/s and/or within the
                applicable return policy period and the same product/s will be
                replaced in return of the defective product/s.
              </li>
              <li>
                Replacement can be for the entire product/s or part/s of the
                product subject to availability of the same with the Seller.
              </li>
            </ol>
            <p>You may also return the Product in an order if:</p>
            <ol>
              <li>
                wrong item being delivered other than what You had ordered in an
                order or
              </li>
              <li>
                Items substantially damaged or deteriorated in quality at the
                time of delivery. You agree that You shall give Us all the
                requisite proofs including but not limited to images of Products
                having issues.
              </li>
            </ol>
            <p>
              Following products shall not be eligible for return or
              replacement:
            </p>
            <ol class="lower-alpha">
              <li>Damages due to misuse of product;</li>
              <li> Any consumable item which has been used/installed;</li>
              <li>Products with tampered or missing serial/UPC numbers;</li>
              <li>
                Any damage/defect which are not covered under the manufacturer's
                warranty.
              </li>
              <li>
                Any product that is returned without all original packaging and
                accessories, including the box, manufacturer's packaging if any,
                and all other items originally included with the product/s
                delivered;
              </li>
            </ol>
            <p>
              <b>Cancellations</b>: With regard to food and perishable items,
              You may cancel an order without charge at any time before the
              Seller accepts the order. You cannot cancel the order post
              acceptance of order by the Seller except if a) the Order could not
              be delivered within the estimated time while placing the order; or
              b) If the Seller doesn't accept or cancels the Order due to
              reasons not attributable to Buyer, including but not limited to
              the store being closed, non-availability of items,
              store/restaurant being unable to service online Orders at that
              moment etc. In all other cases, You may cancel an order without
              charge at any time before the status of the order changes to
              "In-Progress" on Pincode. Pincode reserves the right to look into
              the cancellation request of the Buyer and determine if such
              cancellation request falls under the conditions mentioned above.
              If Pincode is satisfied that the request fulfils any of the
              aforesaid conditions, then Pincode shall process the cancellation
              request and refund any amounts paid by the Buyer.
            </p>
            <p>
              The Seller may cancel an order (in full or partially) for the
              reasons including shortage or unavailability of certain Products
              or force majeure events. In case the Seller cancels any order, You
              will not be charged for such cancellations, and the Seller will
              refund You for any payment already made.
            </p>
            <p>
              Pincode reserves the right to cancel the Orders and initiate full
              refund to the customer in the following circumstances:
            </p>
            <ol class="lower-alpha">
              <li>
                The Logistic Partner is not available to perform the services.
              </li>
              <li>Any Item or product is not in stock</li>
              <li>
                If the transaction cannot be completed for reasons not in
                control of Pincode.
              </li>
            </ol>
            <p>
              You may request return of the Products, purchased from Us provided
              the Products are sealed/unopened/unused and in original condition
              within the return period (This will depend on the product and the
              Seller). Please note, the requests for returns will not be
              accepted post the respective product return period.
            </p>
            <p>
              <b>Refunds</b>: You may at PSSPL's sole discretion be entitled to
              a refund up to 100% of the Order value if the Order is not
              delivered due to a cause attributable to either the Seller App,
              Pincode or the Logistic Partner or in the event packaging of an
              item in an Order or the complete Order, is either tampered or
              damaged and the Buyer refuses to accept at the time of delivery
              for the said reason, subject to the Seller's acknowledgement of
              the same. Please be informed that when You opt to cancel or return
              the Products, upon Our verification of the Products and the
              documents relating thereto, the refund amount for such Products
              which are eligible for return as per these T&amp;C, will be
              processed within a period of seven (7) business days from the date
              of Us verifying and confirming the refund request. However,
              Pincode possesses the right to retain the amount towards forward
              &amp; reverse shipping charges and such other charges as required
              to compensate the associated cost thereupon from the amount
              refundable to the Buyer. Your refund will be processed only when
              the conditions as may be stipulated by Us or the Seller are
              fulfilled. The refund amount will be credited to the source
              account or other presented options provided by Pincode and chosen
              by You. You acknowledge that after initiation of refund, it may
              take additional time for Your refund to reflect in Your account
              which is subject to Your financial institution or payment gateway
              service provider terms and conditions.
            </p>
            <p>
              Pincode further reserves the right to collect a charge from You to
              compensate the Seller or Logistic Partner in cases of
              cancellations not attributable to Pincode, the Seller App or the
              Logistic Partner including but not limited to:
            </p>
            <ol class="lower-alpha">
              <li>Wrong address provided by You;</li>
              <li>
                Unavailability of the Buyer at the time of Order delivery;
              </li>
              <li>
                Any other failure to deliver Order due to lack of information,
                direction or authorization from Buyer at the time of delivery.
              </li>
            </ol>
            <p>
              All decisions with respect to refunds will be at the PSSPL's sole
              discretion and in accordance with PSSPL's internal refund policy
              and the same shall be final and binding.
            </p>
            <p>
              These T&amp;C for acceptance of returns, cancellation and refunds
              shall be subject to reasonable additional conditions, at the
              discretion of the Sellers and the Seller App and Logistic
              Partners, and the same shall be communicated to the User, from
              time to time, through any mode of communication as specified in
              these T&amp;C or as determined by PSSPL.
            </p>
            <p>
              In the event You are claiming return, refund or cancellation of a
              Products, You may raise a ticket with the customer support for
              assistance. Action would be taken basis Your interaction and
              response with the customer support flow as well as the valid proof
              submitted by You.
            </p>
            <p>
              PSSPL reserves its right to initiate civil and/or criminal
              proceedings against a User who, files a invalid and/or false
              claims or provides false, incomplete, or misleading information.
              In addition to the legal proceedings as aforesaid, PSSPL may at
              its sole discretion suspend, block, restrict, cancel the display
              name and its related display names of such User and/or disqualify
              that User and any related users from availing protection through
              Pincode. Any person who, knowingly and with intent to injure,
              defraud or deceive, files a fraudulent complaint containing false,
              incomplete, or misleading information may be guilty of a criminal
              offence and will be prosecuted to the fullest extent of the law.
            </p>
            <h2>
              <b>Terms of service</b>
            </h2>
            <p>
              The Buyer agrees and acknowledges that PSSPL shall not be
              responsible for:
            </p>
            <ol class="lower-alpha">
              <li>The services or goods provided by the Sellers;</li>
              <li>
                The services provided by Logistic Partners not being up to Buyer
                expectations or leading to any loss, harm or damage to him/her;
              </li>
              <li>The availability or unavailability of certain products;</li>
              <li>The Seller delivering incomplete or incorrect Orders; or</li>
              <li>Product liability of goods provided by Sellers.</li>
            </ol>
            <p>
              Buyers and Sellers agree and acknowledge that PSSPL is not
              responsible for any liability arising out of Delivery Services
              provided by the Logistic Partner to them.
            </p>
            <p>
              Buyers may not be able to avail Pincode Services if their delivery
              location is outside Pincode's current serviceable area. Pincode
              will keep the Buyer informed of the same at the time of confirming
              his/her Order booking.
            </p>
            <p>
              Buyer understands that delivery time quoted at the time of
              confirming the Order is an approximate estimate and may vary based
              on the information obtained from Logistic Partners and Sellers.
              PSSPL will not be responsible for any delay in the delivery of a
              product or service.
            </p>
            <p>
              Buyer understands that there are certain Sellers who undertake
              delivery of their goods and services to the Buyer and the Seller
              may charge the Buyer for such service. PSSPL exercises no control
              on such Delivery Services and the same shall be under the control
              of Seller alone and hence all or any disputes arising out of such
              Delivery Services shall be between Buyer and Seller alone. PSSPL
              shall not be responsible for such Delivery Services and assumes no
              liability for disputes arising out of the same.
            </p>
            <p>
              Buyer's Order will be only delivered to the address designated by
              him/her at the time of placing the Order on Pincode. Buyer's Order
              will be cancelled in the event of any change of the address as
              informed by the Logistic Partner. In any such case the delivery of
              goods and services, in the event of change of the delivery
              location, shall be subject to acceptance by the Logistic Partner
              or sole discretion of Pincode.
            </p>
            <p>
              The Buyer understands that the responsibility of fulfilment and
              quality of order lies with the Seller and Pincode does not offer
              any refunds against Products already purchased from a Seller or
              Logistic Partner through Pincode unless an error that is directly
              attributable to Pincode has occurred during the purchase of such
              Products. Further, a Buyer can raise a ticket on Pincode for the
              issues faced by him/her, highlighting the concern(s) to the Seller
              and/or Logistic Partner. Pincode shall communicate such issues or
              grievances to the respective Seller and/or Logistic Partner and
              the said Seller and/or Logistic Partner will provide their
              responses, actions or alternatives (where applicable) to the Buyer
              in response to such ticket.
            </p>
            <p>
              We constantly strive to provide You with accurate information on
              Pincode. However, in the event of an error, We may, in Our sole
              discretion, contact You with further instructions.
            </p>
            <p>
              The Buyer acknowledges that the Pincode Services shall be provided
              through Pincode only during the working hours of the relevant
              Sellers and Logistic Partners.
            </p>
            <h2>
              <b>Third party content and links</b>
            </h2>
            <p>
              Some of the content available through Pincode Services may include
              or link to materials that belong to third parties. Your use of
              such third party links and/or services will be governed by the
              terms of service, and Privacy Policy applicable to the
              corresponding third party. We have no control over, and make no
              representation or endorsement regarding the accuracy, relevancy,
              copyright compliance, legality, completeness, timeliness or
              quality of any product, services, advertisements and other content
              appearing in or linked to from Pincode Services. We do not screen
              or investigate third party material before or after including it
              on Our Pincode Services. Pincode is not responsible for the
              content of any third party sites and does not make any
              representations regarding the content or accuracy of material on
              such sites. If You decide to access/use/visit any such third party
              websites, You do so entirely at Your own risk.
            </p>
            <p>
              Third party content, does not reflect Our views or that of Our
              parent, subsidiary, affiliate companies, branches, employees,
              officers, directors, or shareholders. In addition, none of the
              content available through Pincode Services is endorsed or
              certified by the providers or licensors of such third party
              content. We assume no responsibility or liability for any of Your
              Content shared, provided or published on any third party
              link/sites.
            </p>
            <p>
              You further acknowledge and agree that PSSPL is not liable for any
              loss or damage which may be incurred by You as a result of the
              availability of those external sites or resources, or as a result
              of any reliance placed by You on the completeness, accuracy or
              existence of any advertising, products or other materials on, or
              available from, such websites or resources. Without limiting the
              generality of the foregoing, We expressly disclaim any liability
              for any offensive, defamatory, illegal, invasive, unfair, or
              infringing content provided by third parties.
            </p>
            <h2>
              <b>User Content</b>
            </h2>
            <p>
              You agree that after completion of a transaction on Pincode,
              Pincode will prompt You with an option to provide a rating and/or
              comments/reviews/other inputs provided by You (including but not
              limited to) about the Logistics Partner and Sellers (including but
              not limited to, with respect to the goods sold/services provided
              by them) (the<b>"User Content"</b>). You hereby consent that
              Pincode reserve the right to use, share and display User Content
              in any manner in connection with its business or that of its
              affiliates, including on Pincode, without Your attribution to or
              additional approval. PSSPL and its affiliates reserve the right to
              edit or remove User Content in the event that such User Content
              violates these T&amp;C including but not limited to Your conduct
              clause mentioned Supra, obscenities or other objectionable
              content, include an individual's name or other personal
              information, or violate any privacy laws, other applicable laws or
              PSSPL's policies or its affiliates' content policies (
              <b>"Take Down Policy"</b>). Please note all User Content submitted
              by You are subject to checks and moderation by Our content team.
              Pincode reserves the rights to moderate as well as publish/not
              publish the User Content. Pincode reserve the right to accept,
              reject, moderate, monitor &amp; edit or remove any User Content.
              You grant Pincode the right to use the name that You submit in
              connection with any User Content. You agree not to use a false
              email address, impersonate any person or entity, or otherwise
              mislead as to the origin of any User Content You submit. You are
              and shall remain solely responsible for the User Content, and You
              agree to indemnify PSSPL and its affiliates for all claims
              resulting from any User Content You submit. PSSPL and its
              affiliates take no responsibility and assume no liability for any
              User Content submitted by You.
            </p>
            <p>
              User reviews or ratings on Pincode for Sellers do not reflect the
              opinion of PSSPL. It is pertinent to state that each and every
              review posted on Pincode is the personal opinion of the User only.
              The advertisements published on Pincode are independent of the
              reviews received by such advertisers.
            </p>
            <h2>
              <b>No endorsement</b>
            </h2>
            <p>
              Although PSSPL may market/promote Sellers or Logistic Partners
              from time to time via Pincode, We do not endorse any Seller or
              Logistic Partners. By using the Pincode Services, You agree that
              any legal remedy or liability that You seek to obtain for actions
              or omissions of Seller's, Logistic Partners or any other third
              parties will be limited to a claim against such parties without
              any involving or posing liability on PSSPL.
            </p>
            <h2>
              <b>Access to Pincode, accuracy and security</b>
            </h2>
            <p>
              We do not warrant that Pincode will be compatible with all
              hardware and software which You may use. We shall not be liable
              for damage to, or viruses or other code that may affect, any
              equipment (including but not limited to Your mobile device),
              software, data or other property as a result of Your download,
              installation, access to or use of Pincode or Your obtaining any
              material from, or as a result of using, Pincode. We shall also not
              be liable for the actions of third parties.
            </p>
            <p>
              We do not represent or warrant that the information available on
              Pincode will be correct, accurate or otherwise reliable.
            </p>
            <p>
              We reserve the right to suspend or withdraw access to Pincode to
              You personally, or to all users temporarily or permanently at any
              time without notice. We may any time at Our sole discretion
              reinstate suspended users. A suspended User may not register or
              attempt to register with Us or use Pincode in any manner
              whatsoever until such time that such User is reinstated by Us.
              However, any Order placed by You which has been accepted by the
              Seller and/or Logistic Partner, prior to Your
              suspension/termination from Pincode, will be completed. Further
              any pending refunds, grievances or complaints will be redressed
              even though You may not be able to access Pincode for any
              communication with either Us, the Seller and/or the Logistic
              Partner.
            </p>
            <h2>
              <b>Relationship with operators</b>
            </h2>
            <p>
              PSSPL is not associated, affiliated, sponsored, endorsed or in any
              way linked to any operator, including, without limitation Apple
              and/or Android (<b>"Operator"</b>).
            </p>
            <p>
              Your download, installation, access to or use of Pincode is also
              bound by the terms and conditions of the Operator.
            </p>
            <p>
              You and We acknowledge that these T&amp;C are concluded between
              You and PSSPL only, and not with an Operator, and We, not those
              Operators, are solely responsible for Pincode and the content
              thereof to the extent specified in these T&amp;C.
            </p>
            <p>
              The license granted to You for Pincode is limited to a
              non-transferable license to use Pincode on a mobile device that
              You own or control and as permitted by these T&amp;C.
            </p>
            <p>
              You must comply with any applicable third party terms of agreement
              when using Pincode (e.g. You must ensure that Your use of Pincode
              is not in violation of Your mobile device agreement or any
              wireless data service agreement).
            </p>
            <h2>
              <b>
                Specific terms with respect to use of Pincode for purchase of
                Pharmaceutical Products
              </b>
            </h2>
            <p>
              Buyer understands and agrees that Pincode is merely a technology
              application and the medicines and pharmaceutical products (
              <b>"Pharmaceutical Products"</b>) are sold by the Seller who is a
              pharmacy (<b>"Seller Pharmacy"</b>). PSSPL shall not be held
              responsible in any manner for any error or omission or act
              committed on part of the Seller Pharmacy. All items offered for
              sale on Pincode, and the content made available by the Seller
              Pharmacy, are third party user generated contents and third party
              products. Pincode has no control over such third party user
              generated contents. The authenticity and genuineness of the
              Pharmaceutical Products are made available by the Seller Pharmacy.
              You understand and agree that PSSPL shall have no liability with
              respect to the authenticity of the Pharmaceutical Products being
              facilitated through Pincode.
            </p>
            <p>
              You understand and agree that all commercial / contractual terms,
              with respect to the sale/ purchase/ delivery and consumption of
              the Pharmaceutical Products which are offered by and agreed to
              between You and the Seller Pharmacy and the contract for purchase
              of any of the Pharmaceutical Products, which are offered for sale
              on Pincode by the Seller Pharmacy shall strictly be a bipartite
              contract between the Seller Pharmacy and You. You understand that
              PSSPL is not responsible for any unsatisfactory, delayed,
              non-performance or breach of the contract entered into between You
              and the Seller Pharmacy for purchase and sale of Pharmaceutical
              Products offered by such third party pharmacies on Pincode. PSSPL
              is not responsible if the Seller Pharmacy does not satisfy the
              contract for sale of Pharmaceutical Products and which are out of
              stock, back ordered or otherwise unavailable, but were shown as
              available on Pincode at the time of placement of order by You;
            </p>
            <p>
              Buyer can use Pincode for purchase of various medicines and
              Pharmaceutical Products from the pharmacy stores that requires a
              valid medical prescription issued by a medical expert/ doctor to
              be provided to a registered pharmacist for the purpose of
              dispensing such medicines and Pharmaceutical Products (
              <b>"Prescription Drugs"</b>), offered for sale on Pincode by the
              Seller Pharmacy. In Order to purchase Prescription Drugs from the
              Seller Pharmacy through Pincode, Buyer is required to upload a
              scanned copy of the valid prescription on Pincode. The Order would
              not be processed by the Seller Pharmacy until a copy of a valid
              prescription is uploaded on Pincode, which shall be in turn shared
              with the Seller Pharmacy. The Seller Pharmacy will verify the
              prescription uploaded by Buyer and in case of the Seller Pharmacy
              observes any discrepancy in the prescription uploaded by Buyer,
              the Seller may reject the Order. Buyer is also required to make
              the original prescription available at the time of delivery of the
              Prescription Drugs.
            </p>
            <p>
              Buyer agrees and undertakes that he/she will not repeat the use of
              prescription for which drugs have already been dispensed. In case
              a Buyer found repeating the use of prescription, the Order will be
              cancelled immediately. In any event, PSSPL shall not be
              responsible for any adverse effects or harm caused to Buyer. You
              further acknowledge and accept that the order for a substitute of
              a Prescription Drug would only be processed if the medical expert/
              doctor has himself/ herself permitted for any other equivalent
              generic drug to be dispensed in place of the Prescription Drug in
              the prescription or if the prescription solely lists the salt
              names instead of a specific brand name. You further acknowledge
              and accept that, in the absence of the above, the concerned Seller
              Pharmacy would not dispense a substitute drug in place of the
              Prescription Drug.
            </p>
            <p>
              Buyer agrees and confirms that he/she is completely aware of the
              indications, side effects, drug interactions, effects of missed
              doses or overdose of the medicines the Buyer purchases through
              Pincode. It is imperative to seek professional advice from a
              medical practitioner before purchasing or consuming any medicine.
            </p>
            <p>
              The Seller/PSSPL may maintain a record of the prescriptions
              uploaded by the Buyers.
            </p>
            <h2>
              <b>Intellectual property rights</b>
            </h2>
            <p>
              Pincode owns all intellectual property, including but not limited
              to, all text, videos, graphics, user interfaces, visual
              interfaces, sounds and music (if any), artwork and computer code
              (and any combinations thereof) (collectively, the <b>"Content"</b>
              ) on Pincode. All such Content is licensed to PSSPL and the
              design, structure, selection, coordination, expression, look and
              feel and arrangement of such Content is protected by copyright,
              patent and trademark laws, and various other intellectual property
              rights.
            </p>
            <p>
              The trademarks, logos and service marks displayed on Pincode (
              <b>"Marks"</b>) are the licensed property of PSSPL or owned by
              third parties. You are not permitted to use the Marks without the
              prior consent of PSSPL or the relevant third party (which is the
              owner of the Marks) respectively. Access or use of Pincode does
              not authorise any party to use trademarks, logo, or any other mark
              in any manner.
            </p>
            <p>
              PSSPL disclaims to hold any right, title, or interest in and to
              the intellectual property rights arising out of or associated with
              the Products.
            </p>
            <p>
              There could be references on Pincode of any name, mark, services
              or products of third parties for Your convenience, and in no way
              constitutes Pincode's endorsement, sponsorship or recommendation
              of such third party name, mark, services or products, whether
              express or implied.
            </p>
            <h2>
              <b>Disclaimer of warranties &amp; liability</b>
            </h2>
            <p>
              You expressly understand and agree that, to the maximum extent
              permitted by applicable law:
            </p>
            <ol class="lower-alpha">
              <li>
                Pincode and other Content are provided by Pincode on an "as is"
                basis without warranty of any kind, express, implied, statutory,
                or otherwise, including the implied warranties of title,
                non-infringement, merchantability, or fitness for a particular
                purpose. Without limiting the foregoing, PSSPL makes no warranty
                that Pincode or Pincode Services will meet Your requirements, or
                Your use of Pincode will be uninterrupted, timely, secure, or
                error-free. No advice or information, whether oral or written,
                obtained by You from PSSPL shall create any warranty not
                expressly stated in the T&amp;C.
              </li>
              <li>
                PSSPL will not be liable for any loss that You may incur as a
                consequence of unauthorized use of Your Account or Account
                information in connection with Pincode either with or without
                Your knowledge.
              </li>
              <li>
                PSSPL has endeavoured to ensure that all the information on
                Pincode is correct, but PSSPL neither warrants nor makes any
                representations regarding the quality, accuracy or completeness
                of any data, information regarding the Pincode Services or
                otherwise. PSSPL shall not be responsible for the delay or
                inability to use Pincode or related functionalities, the
                provision of or failure to provide functionalities, or for any
                information, software, functionalities, and related graphics
                obtained through Pincode, or otherwise arising out of the use of
                Pincode, whether based on contract, tort, negligence, strict
                liability or otherwise. Further, PSSPL shall not be held
                responsible for non-availability of Pincode during periodic
                maintenance operations or any unplanned suspension of access to
                Pincode that may occur due to technical reasons or for any
                reason beyond PSSPL's reasonable control.
              </li>
              <li>
                While PSSPL has made best efforts to display the colours of the
                Product image on Pincode as accurately as possible. However, the
                actual colours of the Products You see will depend on Your
                device, and PSSPL does not provide any guarantee in respect of
                such display and will not be responsible or liable for the same.
              </li>
            </ol>
            <h2>
              <b>Indemnification and limitation of liability</b>
            </h2>
            <p>
              You shall indemnify and hold harmless PSSPL, its entities, its
              owner, licensee, affiliates, subsidiaries, group companies (as
              applicable) and their respective officers, directors, agents, and
              employees (<b>"Indemnitees"</b>), from any claim or demand, or
              actions including reasonable attorneys' fees, made by any third
              party or penalty imposed due to or asserted against or incurred by
              the Indemnitees that arise out of, result from, or may be payable
              by virtue of, any breach or non-performance of any representation,
              warranty, covenant or agreement made or obligation to be performed
              by You pursuant to these T&amp;C and/or the Privacy Policy.
              Further, You agree to hold the Indemnitees harmless against any
              claims made by any third party due to, or arising out of, or in
              connection with, Your use of Pincode, any misrepresentation with
              respect to the data or information provided by You, Your violation
              of the T&amp;C and/or the Privacy Policy, Your violation of
              applicable laws, or Your violation of any rights of another,
              including any intellectual property rights.
            </p>
            <p>
              In no event shall PSSPL and its directors, officers, partners,
              consultants, agents, and employees and its third party partners,
              be liable to You or any third party for any special, incidental,
              indirect, consequential, or punitive damages whatsoever, arising
              out of or in connection with Your use of or access to Pincode or
              Content on Pincode.
            </p>
            <p>
              The limitations and exclusions in this section apply to the
              maximum extent permitted by applicable laws.
            </p>
            <h2>
              <b>Violation of these T&amp;C</b>
            </h2>
            <p>
              You agree that any violation by You of these T&amp;C will likely
              cause irreparable harm to PSSPL, for which monetary damages would
              be inadequate, and You consent to PSSPL obtaining any injunctive
              or equitable relief that they deem necessary or appropriate in
              such circumstances. These remedies are in addition to any other
              remedies that PSSPL may have at law or in equity.
            </p>
            <h2>
              <b>Suspension and termination</b>
            </h2>
            <p>
              These T&amp;C will continue to apply until terminated by either
              You or PSSPL as set forth below.
            </p>
            <ol class="lower-alpha">
              <li>
                If You object to the T&amp;C or are dissatisfied with Pincode,
                You may (i) close Your Account on Pincode; and/or (ii) stop
                accessing Pincode.
              </li>
              <li>
                PSSPL may delist You or block Your future access to Pincode or
                suspend or terminate Your Account if it believes, in its sole
                and absolute discretion, that You have violated any term of
                these T&amp;C or the Privacy Policy or in any way otherwise
                acted unethically. Notwithstanding anything in this Clause, all
                terms which by their nature are intended to survive such
                termination, will survive indefinitely unless and until PSSPL
                chooses to terminate them.
              </li>
              <li>
                Any such termination under clause as stated above shall not
                cancel Your obligation to pay for a Product purchased on
                Pincode, or any other obligation which has accrued, or is
                unfulfilled and relates to the period, prior to termination.
              </li>
              <li>
                You shall be liable to pay any fees or charges, if applicable in
                respect of the Pincode Services until the date of termination by
                either party whatsoever.
              </li>
            </ol>
            <h2>
              <b>Governing law and jurisdiction</b>
            </h2>
            <p>
              These T&amp;C shall be governed by and constructed in accordance
              with the laws of India without reference to conflict of laws
              principles and disputes arising in relation hereto shall be
              subject to the exclusive jurisdiction of courts at Bangalore,
              India.
            </p>
            <h2>
              <b>Grievance redressal mechanism</b>
            </h2>
            <p>
              For any order related issue, You may first reach out to Us via
              chat support on Pincode for real time basis resolution. You may
              also register a complaint on
              <a href="nodal@pincode.com">nodal@pincode.com </a> and We will
              strive to resolve Your order related grievance within the
              timelines prescribed under applicable laws.
            </p>
            <p>
              If You still have any grievances, or complaints or concerns with
              respect to Pincode or order or are not satisfied with the
              resolution, the Content, or the Pincode Services, You can contact
              the designated grievance cum Nodal Officer of the Company. Details
              of the Grievance cum Nodal Officer is provided hereunder, in
              accordance with the Information Technology Act, 2000 and the rules
              made thereunder and (2) Consumer Protection (E-Commerce) Rules
              2020 and rules made thereunder:
            </p>
            <p>
              <b>Contact:</b>
            </p>
            <p>Principal Nodal Officer - Mayank Bagaria</p>
            <p>
              <span>Email:</span>
              <a href="mailto:nodal@pincode.com">nodal@pincode.com</a>
            </p>
            <p>
              Address: Office-2, Floor 6, Wing B, Block A, Salarpuria Softzone,
              Bellandur Village, Varthur Hobli, Outer Ring Road, Bangalore
              South, Bangalore, Karnataka, India, 560103
            </p>
            <p> Working hours: Mon-Fri 10 am to 7 pm</p>
            <p>
              The Grievance Officer of PSSPL shall endeavour to acknowledge the
              User grievances, or complaints or concerns with respect to
              Pincode, the Content, or the Pincode Services, within 48 hours of
              receipt of the same and shall endeavour to redresses the same at
              the earliest and in no event later than 30 (thirty) days of
              receipt of such request. By lodging a complaint or grievance, You
              agree to provide complete support to the grievance officer and
              such reasonable information as may be sought by them from You.
            </p>
            <p>
              If the grievance is still not resolved to Your satisfaction, then
              You may opt for resolution via the Online Dispute Resolution
              (“ODR”) process, as provided for under the ONDC policies, provided
              that You opt for ODR within the specified time period as per ONDC
              policies, beyond which the issue may be recorded as closed by
              PSSPL.
            </p>
            <h2>
              <b>Communications</b>
            </h2>
            <p>
              PSSPL and its entities may communicate with You on the contact
              information that You may have provided to Us during the course of
              Your engagement, including but not limited to signup, transacting
              or availing any third party Products on Pincode.
            </p>
            <p>
              We will send You communication alerts via emails or SMS or push
              notifications or via other progressive technology. You also agree
              that there can be disruption in communications due to factors that
              are not under Our control, including but not limited to Your phone
              being switched off, incorrect email address, network
              interruptions. You agree not to hold PSSPL liable for non-delivery
              for any alert or any loss suffered by You due to delay, distortion
              or failure of communication.
            </p>
            <p>
              You further acknowledge that You are responsible for the contact
              details shared with Us and shall update Us on any change on Your
              contact details. You authorize Us to contact You and communicate
              with You for any Pincode Service or offer(s). We may use third
              party service providers to send alerts or communicate with You.
              You authorize PSSPL and its entities to override the do not
              disturb settings to reach out to You over calls, SMS, emails and
              any other mode of communication.
            </p>
            <h2>
              <b>General provisions</b>
            </h2>
            <p>
              Notice: All notices from PSSPL will be served by email to Your
              registered email address or by messaging app on the registered
              mobile phone number or by general notification on Pincode.
            </p>
            <p>
              Assignment: You cannot assign or otherwise transfer any rights
              granted hereunder to any third party. PSSPL's rights under these
              T&amp;C are freely transferable by PSSPL to its successor or any
              third party without the requirement of seeking Your consent.
            </p>
            <p>
              Severability: If, for any reason any provision of the T&amp;C, or
              any portion thereof, to be unenforceable, that provision shall be
              enforced to the maximum extent permissible so as to give effect to
              the intent of the parties as reflected by that provision, and the
              remainder of the T&amp;C shall continue in full force and effect.
            </p>
            <p>
              Force Majeure: PSSPL and/or Pincode shall not be liable to You for
              its failure to perform or for delay in providing You access to
              Your Account or to Pincode or any Pincode Services thereof, to the
              extent such failure or delay results from causes beyond its
              reasonable control, including, without limitation, acts of God,
              fires, explosions, wars or other hostilities, insurrections,
              revolutions, strikes, labour unrest, earthquakes, floods,
              pandemic, epidemics or regulatory or quarantine restrictions,
              unforeseeable governmental restrictions or controls or a failure
              by a third party hosting provider or internet service provider or
              on account of any change or defect in the software and/or hardware
              of Your computer system.
            </p>
            <p>
              Waiver: If You breach these T&amp;C and We take no action, We will
              still be entitled to use Our rights and remedies in any other
              situation where You breach these conditions.
            </p>
          </div>
        </Container>
      </div>
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default TermsConditions;
