import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import FooterData from "../../data/Footer/footerItem.json";
import FooterLinkItem from "../../components/Footer/FooterLinkItem.jsx";
import NewsletterForm from "../../components/Newsletter/NewsletterForm";

const Footer = () => {
  return (
    <div className="footer-section section footer-bg-color">
      <div className="container">
        <div className="row mb-lg-14 mb-md-10 mb-6">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
            <div className="footer-widget">
              <div className="footer-logo">
                <Logo image={`/images/logo/logo.png`} />
              </div>
              <div className="footer-widget-content">
                <div className="content">
                  <p>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      +91 7899916434
                    </Link>
                  </p>
                  <p>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      info@OneEventas.net
                    </Link>{" "}
                  </p>
                </div>
                <div className="footer-social-inline">
                  <a href="#">
                    <i className="fab fa-twitter-square"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {FooterData &&
            FooterData.map((single, key) => {
              return (
                <div
                  key={key}
                  className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-6"
                >
                  <FooterLinkItem data={single} key={key} />
                </div>
              );
            })}

          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
            <div className="footer-widget">
              <div className="footer-widget-title">
                <h4 className="footer-widget-title">Contact Us</h4>
              </div>

              <div className="footer-widget-content end_widget">
                <div className="content">
                  <p>
                    <span>
                      <i className="fa fa-map-marker"></i>{" "}
                    </span>
                    Office address: 29/2662, 36th cross rd , 28th main ,
                    jaynagar 9th block bengaluru, karnataka 560069, india
                  </p>
                  <p>
                    <span>
                      <i className="fa fa-phone"></i>{" "}
                    </span>
                    +91 7899916434
                  </p>{" "}
                  <p>
                    <span>
                      {" "}
                      <i className="fa fa-envelope"></i>{" "}
                    </span>
                    info@OneEventas.net
                  </p>{" "}
                  <NewsletterForm mailchimpUrl="//devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className="copyright">
              &copy; {new Date().getFullYear()} <strong>OneEventas</strong>{" "}
              Created <i className="fas fa-heart text-danger"></i> by{" "}
              <a href="#">
                <strong>OneEventas PVT. LTD</strong>
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
